
import { Component, Vue, Watch } from 'vue-property-decorator'
import { commonModule, clientsModule, installationsModule } from '@/store/modules/store-accessor'
import Dropdown from '../components/dropdown/Dropdown.vue'
import DropdownItem from '../components/dropdown/DropdownItem.vue'
import { convertEnglishToGreek, sortTableDates } from "@/helpers/data";
import { TimeUtil } from '@/helpers/TimeUtil';
import ClientFilter from '../components/Filter/ClientFilter.vue'



@Component({
  components: { Dropdown, DropdownItem, ClientFilter }
})
export default class Clients extends Vue {
  tableheaders: any [] = [
    { text: '', align: 'left', class: 'row-style', sortable: false, value: 'is_business' },
    { text: this.$t("companyName"), sortable: true, class: 'row-style', value: 'fullname' },
    { text: this.$t("vat"), sortable: false, class: 'row-style', value: 'vat' },
    { text: this.$t("phone"), align: 'left', sortable: true, class: 'row-style', value: 'phone' },
    { text: this.$t("notes"), align: 'left', sortable: true, class: 'row-style', value: 'notes' },
    { text: this.$t("created"), sortable: true, class: 'row-style', value: 'created_at', sort: (a: string, b: string) => sortTableDates(a, b) },
    { text: this.$t("email"), sortable: true, class: 'row-style', value: 'email' },
    { text: '', align: 'right', sortable: false, class: 'row-style', value: 'id' } 
    ]

  model: any = {
    search: '',
    selected: [] as string[]
  }

  timer: number;
  query: any;

   onSearchChange(val: string) {
    clearTimeout(this.timer)

    this.timer = setTimeout(async () => {
      clientsModule.setIsLoadingTable(true);
      let params = new URLSearchParams();
      if (val && val.length > 0) {
        val = convertEnglishToGreek(val);
        clientsModule.setSearch(val);
        clientsModule.queryObject.search = clientsModule.search;
      } else {
        clientsModule.setSearch('');
        delete clientsModule.queryObject.search;
      }
      this.$router.replace({ query: clientsModule.queryObject });
      
      // Check if user selected any of the queries
      const queryIndex = this.$route.fullPath.indexOf('?');
      if(queryIndex >= 0) {
        const query = this.$route.fullPath.substring(queryIndex);
        clientsModule.setFilters(query);
      } else {
        clientsModule.setFilters("");
      }

      await clientsModule.getClientsAction(clientsModule.filters);
      clientsModule.setIsLoadingTable(false);
    }, 500);
  }

  get clientsLength() {
    return this.clients.length
  }

  get searchLabel() {
    return this.$t("clientsSearch")
  }

  get clients(){
    return clientsModule.clients
  }

  get isLoadingTable() {
    return clientsModule.isLoadingTable;
  }

  get currClient(){
    return clientsModule.currentClient
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDate(date)
  }

  async mounted () {
    /*
     TODO: Handle clients properly when UX is clear.
     */
    clientsModule.getClientsCount();
    clientsModule.getClientsAction('');
    clientsModule.setFilters('');
    clientsModule.setSearch('');
    clientsModule.setQueryObject({});
    commonModule.getContactTypeCombo();
    commonModule.initSnackbar({});
  }

  destroyed() {
    clientsModule.clearClients();
  }

  async selectClient (client: any) {
    try {
      clientsModule.setIsLoadingTable(true);
      await clientsModule.getClientsInfo(client.id)
      commonModule.showSideview({ name: 'clients-sideview', payload: { clientId: client.id } })
    } catch (error) {
      clientsModule.setIsLoadingTable(false);
    }
  }

  addClient (){
    commonModule.showModal({ name: 'add-client-modal' })
  }

  async deleteClient (item: any){
    await installationsModule.getInstallationsByIdAction(item.id);
    commonModule.showModal({ name: 'delete-client-modal', payload: { client: item}})
  }

  async editClient (client: any){
    await clientsModule.getClientsInfo(client.id)
    commonModule.showModal({ name: 'edit-client-modal', payload: { client: client, view: "clients"}})
  }

  async refreshData() {
    await clientsModule.getClientsCount();
   await clientsModule.getClientsAction(clientsModule.filters);
  }

}
