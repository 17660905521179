
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  commonModule,
  tasksModule,
  actionTypesModule,
  itemsModule,
  companyModule,
  dashboardModule,
  installationsModule,
  appointmentsModule,
} from "@/store/modules/store-accessor";
import { TimeUtil } from "@/helpers/TimeUtil";
import AssigneeCombo from "@/components/Combobox/AssigneeCombo.vue";
import { getOptimalTextColor, primaryItemText } from "@/helpers/data";
import _ from "lodash";

@Component({
  components: {
    AssigneeCombo,
  },
})
export default class AddTaskModal extends Vue {
  @Prop()
  payload!: any;

  payloadAssignee = {} as any;

  isEditingTask = false;
  loadingInstallationPrimaryItems = true;
  loadingInstallations = true;
  disableInstallationPrimaryItems = true;

  dateMenu = false;
  timeMenu = false;
  taskModel = {} as any;

  payloadTaskModel = {} as any;

  updateAssignee(assignee: any) {
    this.taskModel.assignee_id = assignee?.id || null;
    this.taskModel.assignee = assignee;
  }

  get actionsCombo() {
    return actionTypesModule.actionTypesCombo;
  }

  get installtionPrimaryItems() {
    let items = itemsModule.installationPrimaryItems;

    return items;
  }

  get isLoadingInstallationPrimaryItems() {
    return this.loadingInstallationPrimaryItems;
  }

  get disablePrimaryItems() {
    return (this.loadingInstallationPrimaryItems || this.installtionPrimaryItems.length === 0);
  }

  get installationsCombo() {
    return commonModule.installationsCombo;
  }

  get isLoadingInstallations() {
    return this.loadingInstallations;
  }

  get installationDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.installation_description;
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  getItemText(ids: number) {
    const idArray = Array.isArray(ids) ? ids : [ids];
    const arr: any = this.installtionPrimaryItems;

    for (let i = 0; i < arr.length; i++) {
      if ((Array.isArray(arr[i].id) && arr[i].id.some((item: any) => idArray.includes(item))) || (!Array.isArray(arr[i].id) && idArray.includes(arr[i].id))) {
        (itemsModule.installationPrimaryItems as any).push({primary_items: arr[i].primary_items,id: ids});
        return { primary_items: arr[i].primary_items, id: ids };
      }
    }
    return { primary_items: "", id: 0 };
  }

  notesRule = [
    (val: any) => val?.length <= 1000 || this.$i18n.t("notesLengthRule"),
  ];
  titleRule = [
    (val: any) => !!val || this.$i18n.t("title_required"),
    (val: any) => val.length <= 300 || this.$i18n.t("form_title_length_rule"),
  ];
  scheduledTimeRules() {
    if (this.taskModel.due_at) {
      return [(val: any) => !!val || this.$i18n.t("timeRequired")];
    }
    return [];
  }

  async hideModal() {
    this.payload.isOrphanTask ? commonModule.hideSecondModal(): commonModule.hideModal();
  }

  getAssigneeId(name: string) {
    const combo: any = commonModule.personnelPartnersCombo;
    const foundItem = combo.find((item: any) => item.name === name);
    if (foundItem) {
      return { name: foundItem.name, id: foundItem.id };
    } else {
      return "";
    }
  }

  async beforeMount() {
    actionTypesModule.getActionTypesCombo();

    this.payloadTaskModel.id = this.payload.task.id;
    this.payloadTaskModel.due_at = this.formatEditDate(
      this.payload.task.due_at
    );
    this.payloadTaskModel.assignee_id = this.payload.task.assignee?.id;
    this.payloadTaskModel.assignee = this.payload.task.assignee;
    this.payloadTaskModel.installation_id = this.payload.task.installation_id;
    this.payloadTaskModel.task_status_id = this.payload.task.task_status_id;
    this.payloadTaskModel.title = this.payload.task.title ? this.payload.task.title : this.payload.task.category.includes(1) ? this.$i18n.t("reminder_label") : "";
    this.payloadTaskModel.notes = this.payload.task.notes || "";
    this.payloadTaskModel.action_type_id = this.payload.task.action_type_id || null;
    this.payloadTaskModel.installation_item_id = this.getItemText(this.payload.task.installation_item_id);
    this.payloadTaskModel.partner_id = this.payload.task.partner_id || "";
    this.payloadTaskModel.all_day = this.payload.task.all_day;
    this.payloadTaskModel.scheduled_start_time = this.payloadTaskModel.all_day === false ? this.formatEditDateTime(this.payload.task.due_at) : "";
    itemsModule.getInstallationPrimaryInventoryItems(this.payload.task.installation_id);
    this.taskModel = _.cloneDeep(this.payloadTaskModel);
  }

  getInstallationId(selectedInstallationId: string) {
    this.payloadTaskModel.installation_item_id = null;
    this.loadingInstallationPrimaryItems = true;
    this.disableInstallationPrimaryItems = true;
    if (selectedInstallationId) {
      itemsModule.getInstallationPrimaryInventoryItems(selectedInstallationId);
    }
    this.loadingInstallationPrimaryItems = false;
    this.disableInstallationPrimaryItems = false;
  }

  async mounted() {
    if (this.taskModel.installation_id) {
      await itemsModule.getInstallationPrimaryInventoryItems(this.payload.task.installation_id);
    } else {
      this.loadingInstallations = true;
      await commonModule.getInstallationsCombo();
      this.loadingInstallations = false;
    }
    commonModule.initSnackbar({});
    commonModule.getPersonnelCombo();
    this.payloadTaskModel.installation_item_id = this.getItemText(this.payload.task.installation_item_id);
    this.loadingInstallationPrimaryItems = false;
  }

  get editDisabled() {
    return _.isEqual(this.taskModel, this.payloadTaskModel);
  }

  get showTimeInput() {
    return !this.taskModel.all_day;
  }

  formatDate(date: Date) {
    if (date == undefined || date == null) {
      return this.$i18n.t("pending");
    } else {
      return TimeUtil.formatDate(date);
    }
  }

  formatEditDate(date: Date): string {
    return TimeUtil.formatDateOnly(date);
  }

  formatEditDateTime(date: Date): string {
    return TimeUtil.formatTimeOnly(date);
  }

  installationText(item: any) {
    let installation_arr = item.is_business
      ? [
          item.tag_id.split("_").pop(),
          `${item.address ? item.address : item.area}`,
          item.description && this.installationDescriptionIsEnabled
            ? item.description
            : "",
          `${item.entity_name} (${item.client_name})`,
          item.phone,
        ]
      : [
          item.tag_id.split("_").pop(),
          `${item.address ? item.address : item.area}`,
          item.description && this.installationDescriptionIsEnabled
            ? item.description
            : "",
          item.client_name,
          item.phone,
        ];

    return installation_arr.filter(Boolean).join(" | ");
  }

  async doEditTask() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      let taskModelCopy = _.cloneDeep(this.taskModel);

      this.isEditingTask = true;
      await tasksModule.editTask(taskModelCopy);
      this.isEditingTask = false;
      tasksModule.getPendingTasks();

      if (this.$route.name === "Dashboard") {
        dashboardModule.getDashboardPendingTasks(dashboardModule.taskFilters);
      } else if (this.$route.name === "Calendar") {
        appointmentsModule.unsetCalendarAppointments();
        appointmentsModule.getCalendarAppointmentsAction(appointmentsModule.filters);
      }

      if (this.payload.isOrphanTask && this.taskModel.installation_id) {
        await installationsModule.getInstallationInfo(this.taskModel.installation_id);
        commonModule.hideSecondModal();
        commonModule.hideModal();

        tasksModule.setIsLoadingTable(false);
        commonModule.showSideview({
          name: "installations-sideview",
          payload: {
            installationId: this.taskModel.installation_id,
            tab: 3,
            taskId: this.taskModel.id,
          },
        });
      }

      this.hideModal();
    }
  }
}
