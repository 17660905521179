import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import InstallationsApi from '@/api/installation-api'
import { TimeUtil } from '@/helpers/TimeUtil'
import { companyModule, filterModule } from '@/store/modules/store-accessor'
import _ from 'lodash';
@Module({ namespaced: true, name: "installations" })
export default class InstallationModule extends VuexModule {
  public installations: InstallationDto[] = [];
  public installationsById: InstallationDto[] = [];
  public installationsTable: InstallationTable[] = [];
  public currentInstallation: InstallationDetailsDto =
    {} as InstallationDetailsDto;
  public installationsCount = 0;
  public jobs: Array<any> = [];
  public installationNextTagId = 0;

  public installationInfo: string;
  public installationItems: string;
  public installationJobs: string;
  public installationPhotos: string;
  public installationTypeCombo: string;
  public itemTypeCombo: string;
  public fuelTypeCombo: string;
  public actionTypeCombo: string;
  public isLoadingSideView = true;
  public isLoadingTable = true;
  public params = new URLSearchParams();
  public filtersParams = new URLSearchParams();
  public search = '';
  public filters = "";
  public queryObject: any = {};
  public newInstallationCoordinates: Array<string> = [];

  @Action({ commit: "setInstallations" })
  async getInstallationsAction(query: string): Promise<InstallationDto[]> {
    const installationsApi = new InstallationsApi();
    this.setIsLoadingTable(true);
    const installations = await installationsApi.getAllInstallations(query);
    await installationsApi.getAllInstallationsCount();
    await filterModule.getInstallationsFilters();
    this.setIsLoadingTable(false);

    return installations;
  }

  @Action({ commit: "setInstallationsById" })
  async getInstallationsByIdAction(clientId: string): Promise<InstallationDto[]> {
    const installationsApi = new InstallationsApi();

    const installations = await installationsApi.getAllInstallationsById(clientId);

    return installations;
  }

  @Action({ commit: "setInstallationsCount" })
  async getInstallationsCount(): Promise<CountDto[]> {

    const installationsApi = new InstallationsApi();
    const installactionsCount =
    await installationsApi.getAllInstallationsCount();
    return installactionsCount;
  }

  @Action({ commit: "setCurrentInstallation" })
  async getInstallationInfo(
    currentInstallationId: string
  ): Promise<InstallationDetailsDto> {

    const installationsApi = new InstallationsApi();
    const installationDetails = await installationsApi.getInstallation(
      parseInt(currentInstallationId)
    );
    return installationDetails;
  }

  @Action({ commit: "setInstallationNextTagId" })
  async getInstallationNextTagId(): Promise<InstallationNextTagIdDto> {
    const installationsApi = new InstallationsApi();
    const installationNextTagId = await installationsApi.getInstallationNextTagId();
    return installationNextTagId;
  }

  @Action
  async deleteInstallation(data: Record<string, any>) {

    const installationsApi = new InstallationsApi()
    await installationsApi.deleteInstallation(data.id)
    await this.getInstallationsAction(this.filters);
  }

  @Action
  async getInstallationPDF(currentInstallationId: string) {
    const installationsApi = new InstallationsApi();
    const response = await installationsApi.getInstallationPDF(parseInt(currentInstallationId));

    const blob = new Blob([response], { type: "application/pdf" });
    return blob
  }

  @Action
  async deleteInstallationItem(item: Record<string, any>) {
    
    const installationsApi = new InstallationsApi()
    await installationsApi.deleteInstallationItem(item.installation_id, item.id)
    await this.getInstallationInfo(item.installation_id);
    await this.getInstallationsAction(this.filters);
  }

  @Action
  async updateInstallationItem(data: Record<string, any>) {

    if (data.cost == '') {
      delete data.cost
    }
    if (!data.installed_at) {
      delete data.installed_at
    }
    if (data.floor == "") {
      delete data.floor
    }
    if (data.notes == "") {
      delete data.notes
    }
    if (data.serial_number == "") {
      data.serial_number = null;
    }
    if (data.notes == "") {
      delete data.notes
    }
    if (data.warranty == "") {
      delete data.warranty
    }
    if (!data.fuel_type_id) {
      data.fuel_type_id = null;
    } else {
      data.fuel_type_id = data.fuel_type_id.id;
    }
    if (!data.installation_placement_type_id) {
      data.installation_placement_type_id = null;
    }
    else {
      data.installation_placement_type_id = data.installation_placement_type_id.id;
    }

    data.item_type_id = data.selected_item.item_type_id;
    data.make = data.selected_item.make;
    data.model = data.selected_item.model;
    delete data.selected_item;

    const installationsApi = new InstallationsApi()
    await installationsApi.updateInstallationItem(data.installation_id, data.id, data)
    await this.getInstallationInfo(data.installation_id);
    await this.getInstallationsAction(this.filters);
  }

  @Action
  async addInstallationItem(data: Record<string, any>) {
    const installation_item_data =_.cloneDeep(data);

    if (installation_item_data.cost == '') {
      delete installation_item_data.cost
    }
    if (installation_item_data.installed_at == "") {
      delete installation_item_data.installed_at
    }
    if (installation_item_data.floor == "") {
      delete installation_item_data.floor
    }
    if (installation_item_data.notes == "") {
      delete installation_item_data.notes
    }
    if (installation_item_data.serial_number == "") {
      delete installation_item_data.serial_number
    }
    if (installation_item_data.notes == "") {
      delete installation_item_data.notes
    }
    if (installation_item_data.warranty == "") {
      delete installation_item_data.warranty
    }
    if (!installation_item_data.fuel_type_id) {
      delete installation_item_data.fuel_type_id;
    } else {
      installation_item_data.fuel_type_id = installation_item_data.fuel_type_id.id;
    }
    if (!installation_item_data.installation_placement_type_id) {
      delete installation_item_data.installation_placement_type_id
    }
    else {
      installation_item_data.installation_placement_type_id = installation_item_data.installation_placement_type_id.id;
    }

    installation_item_data.item_type_id = installation_item_data.selected_item.item_type_id;
    installation_item_data.make = installation_item_data.selected_item.make;
    installation_item_data.model = installation_item_data.selected_item.model;
    delete installation_item_data.selected_item;

    const installationsApi = new InstallationsApi()
    await installationsApi.createInstallationItem(installation_item_data.installation_id, installation_item_data)
    await this.getInstallationInfo(installation_item_data.installation_id);
    await this.getInstallationsAction(this.filters);
  }

  @Action
  async addInstallation(data: Record<string, any>) {
    const installation_data = _.cloneDeep(data);

    installation_data.client_id = installation_data.client.id;
    delete installation_data.client

    if (installation_data.address == null || installation_data.address == '') {
      delete installation_data.address
    }
    else {
      installation_data.address = installation_data.address.split(",")[0];
    }
    if (installation_data.zip_code == "") {
      delete installation_data.zip_code
    }
    if (installation_data.floor == "") {
      delete installation_data.floor
    }
    if (installation_data.latitude == "") {
      delete installation_data.latitude
    }
    if (installation_data.longitude == "") {
      delete installation_data.longitude
    }
    if (installation_data.notes == "") {
      delete installation_data.notes
    }
    if (installation_data.owner === null || installation_data.owner.name == "") {
      delete installation_data.owner
    }
    else {
      installation_data.owner_id = installation_data.owner.id;
    }

    installation_data.tag_id = companyModule.company.key + "_" + data.tag;
    delete installation_data.tag

    if (!installation_data.selected_item.item_type_id) {
      delete installation_data.selected_item;
    }
    else {
      installation_data.installation_items = [installation_data.selected_item];
      delete installation_data.selected_item;
    }

    const installationsApi = new InstallationsApi()
    const response = await installationsApi.createInstallation(installation_data)
    await this.getInstallationsAction(this.filters);
    return response;
  }

  @Action
  async editInstallation(data: Record<string, any>) {

    data.client_id = data.client.id;
    delete data.client

    if (data.address == null) {
      delete data.address
    }
    else {
      data.address = data.address.split(",")[0];
    }
    if (data.latitude == "") {
      delete data.latitude
    }
    if (data.longitude == "") {
      delete data.longitude
    }
    
    if (data.owner == null) {
      data.owner_id = null;
    }
    else if (data.owner.id == null) {
      data.owner_id = null;
    }
    else {
      data.owner_id = data.owner.id;
    }

    data.tag_id = companyModule.company.key + "_" + data.tag;
    delete data.tag

    const installationsApi = new InstallationsApi()
    await installationsApi.updateInstallation(data.id, data)

    if (data.view == "installations") {
      await this.getInstallationsAction(this.filters);
    } else {
      this.setIsLoadingTable(true);
      await this.getInstallationInfo(data.id);
      await this.getInstallationsAction(this.filters);
      this.setIsLoadingTable(false);
    }
  }

  @Action
  async editInstallationPhoto(data: Record<string, any>) {
    const installationsApi = new InstallationsApi()
    await installationsApi.updateInstallationPhoto(data.installationId, data.photoId, data)
  }

  @Action
  async editInstallationFile(data: Record<string, any>) {
    const installationsApi = new InstallationsApi()
    await installationsApi.updateInstallationFile(data.installationId, data.fileId, data)
  }

  @Action
  async addInstallationPhoto({installationId, photo} : {installationId: string, photo: any}) {
    const installationsApi = new InstallationsApi()
    const response = await installationsApi.createInstallationPhoto(installationId, photo)
    return response;
  }

  @Action
  async addInstallationFile({installationId, file} : {installationId: string, file: any}) {
    const installationsApi = new InstallationsApi()
    const response = await installationsApi.createInstallationFile(installationId, file)
    return response;
  }

  @Action
  async getInstallationFile({installationId, fileId} : {installationId: string, fileId: string}) {
      const installationsApi = new InstallationsApi();
      const response = await installationsApi.getInstallationFile(installationId, fileId);
      const blob = new Blob([response], { type: "application/file" });
      return blob
  }

  @Action
  async getInstallationPhoto({installationId, photoId} : {installationId: string, photoId: string}) {
      const installationsApi = new InstallationsApi();
      const response = await installationsApi.getInstallationPhoto(installationId, photoId);
      const blob = new Blob([response], { type: "image/jpeg" });
      return blob
  }

  @Action
  async deleteInstallationFile({installationId, fileId} : {installationId: string, fileId: string}) {
    const installationsApi = new InstallationsApi()
    await installationsApi.deleteInstallationFile(installationId, fileId);
  }

  @Action
  async deleteInstallationPhoto({installationId, photoId} : {installationId: string, photoId: string}) {
    const installationsApi = new InstallationsApi()
    await installationsApi.deleteInstallationPhoto(installationId, photoId);
  }

  @Mutation
  public setInstallations (installations: InstallationDto[]): void {
    try {
      installations.map(item => {
        item.client_name = `${item.client_lastname} ${item.client_firstname}`
        item.tag_split = item.tag_id.split('_').pop();
        item.readable_created_at = TimeUtil.formatDateTime(item.created_at)
        item.readable_updated_at = TimeUtil.formatDateTime(item.updated_at)
        item.readable_last_job_entry_at = `${TimeUtil.formatDateTime(item.last_job_entry_at)}`
      })
      this.installations = installations
      this.isLoadingTable = false;
    } catch(error) {
      this.isLoadingTable = false;
    }
  }

  @Mutation
  public clearInstallations(): void {
    this.installations = [];
  }

  @Mutation
  public setInstallationsCount(installationCount: CountDto): void {
    this.installationsCount = installationCount.count;
  }

  @Mutation
  public setInstallationNextTagId(installationNextTagId: InstallationNextTagIdDto): void {
    this.installationNextTagId = installationNextTagId.tag_id;
  }

  @Mutation
  public setCurrentInstallation(installationInfo: InstallationDetailsDto): void {
    installationInfo.pending_tasks = installationInfo.tasks.filter(x => x.task_status_id == 2)
    installationInfo.tasks = installationInfo.tasks.filter(x => x.task_status_id != 2)

    this.currentInstallation = installationInfo;
    this.isLoadingSideView = false;
  }

  @Mutation
  public setInstallationsById (installationsById: InstallationDto[]): void {

    installationsById.map(installation => {
      installation.tag_split = installation.tag_id.split('_').pop();
    })
    this.installationsById = installationsById
  }

  @Mutation
  public unsetCurrentInstallation(): void {
    this.currentInstallation = {} as InstallationDetailsDto;
  }

  get mapJobsAndActions(): InstallationJobList[] {

    const newJobs = this.currentInstallation.jobs.map((job) => {

      let actions = this.currentInstallation.actions.filter((action) => {
        return action.job_id === job.id;
      });

      let materials = this.currentInstallation.materials.filter((materials) => {
        return materials.job_id === job.id;
      });

      let payments = this.currentInstallation.payments.filter((payment) => {
        return payment.job_id === job.id;
      });

      let expenses = this.currentInstallation.expenses.filter((expense) => {
        return expense.job_id === job.id;
      });

      let appointments = this.currentInstallation.appointments.filter(
        (appointment) => {
          return appointment.job_id === job.id;
        }
      );

      actions = actions.map((item) => {
        const newItem = item;
        
        newItem.entry_type = 'action';
        return newItem;
      })

      materials = materials.map((item) => {
        const newItem = item;
        
        newItem.entry_type = 'material';
        return newItem;
      })

      appointments = appointments.map((item) => {
        const newItem = item;
        
        newItem.entry_type = 'appointment';
        return newItem;
      })

      payments = payments.map((item) => {
        const newItem = item;
        
        newItem.entry_type = 'payment';
        return newItem;
      })

      expenses = expenses.map((item) => {
        const newItem = item;
        
        newItem.entry_type = 'expense';
        return newItem;
      })

      
      const actionsAndPaymentsAndAppointments: Record<string, any>[] = ((actions as []).concat(materials as [])).concat(payments as []).concat(expenses as []);

      actionsAndPaymentsAndAppointments.sort((a, b) => {
        const c: any = new Date(a.created_at);
        const d: any = new Date(b.created_at);
        return c - d;
      });
      
      job.actions = [];
      job.actions = actionsAndPaymentsAndAppointments;

      return job;
    });

    return newJobs.filter((item) => {
      return (item.actions.length > 0)
    })
  }

  @Mutation
  public setIsLoadingSideView(isLoadingSideView: boolean): void {
    this.isLoadingSideView = isLoadingSideView
  }

  @Mutation
  public setIsLoadingTable(isLoadingTable: boolean): void {
    this.isLoadingTable = isLoadingTable
  }

  @Mutation
  public setParams(params: URLSearchParams): void {
    if(this.search != '') {
      params.set('search', this.search);
    }
    this.params = params
  }

  @Mutation
  public setSearch(search: string): void {
    this.search = search
  }

  @Mutation
  public setFilters(filters: string): void {
    this.filters = filters
  }

  @Mutation
  public setQueryObject(queryObject: any): void {
    this.queryObject = queryObject
  }

  @Mutation
  public setNewInstallationCoordinates(newInstallationCoordinates: any): void {
    this.newInstallationCoordinates = newInstallationCoordinates;
  }

  @Mutation
  public clearNewInstallationCoordinates(): void {
    this.newInstallationCoordinates = [];
  }

}
