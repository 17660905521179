
import { Component, Vue, Watch } from 'vue-property-decorator'
import { commonModule, paymentsModule, companyModule, expensesModule, installationsModule, jobsModule } from '@/store/modules/store-accessor'
import FilterDropdown from '../components/dropdown/filter/FilterDropdown.vue'
import PaymentFilter from '../components/Filter/PaymentFilter.vue'
import { formatAmount, sortTableDates, pagesExportLimit, templatesFilenameLanguage } from "@/helpers/data";
import dayjs from 'dayjs';
import Dropdown from '../components/dropdown/Dropdown.vue'
import DropdownItem from '../components/dropdown/DropdownItem.vue'
import FileSaver from 'file-saver';
import InstallationModule from '@/store/modules/installations-module';

@Component({
  components: { FilterDropdown, PaymentFilter, Dropdown, DropdownItem }
})
export default class Payments extends Vue {
  tableheaders: any [] = [
    { text: this.$t("type"), sortable: true, class: 'row-style', value: 'finc_type' },
    {
      text: this.$t("tag"),
      align: 'left',
      class: 'row-style',
      sortable: true,
      value: 'tag_split'
    },
    { text: this.assigneeLabel, sortable: true, class: 'row-style', value: 'assignee_id' },
    { text: this.$t("created_by"), sortable: true, class: 'row-style', value: 'created_by' },
    { text: this.$t("created_at"), sortable: true, class: 'row-style', value: 'readable_created_at', sort: (a: string, b: string) => sortTableDates(a, b) },
    { text: this.$t("amount"), sortable: true, class: 'row-style', value: 'amount', align: 'center'},
    { text: this.$t("payment_type"), sortable: true, class: 'row-style', value: 'payment_type_id' },
    { text: this.$t("notes"), sortable: true, class: 'row-style', value: 'notes' },
    { text: '', align: 'right', sortable: false, class: 'row-style', value: 'id' }
  ]

  model: any = {
    search: '',
    selected: [] as string[]
  }

  isDownloadingPaymentsPdf = false

  get assigneeLabel() {
    if(companyModule.company?.modules?.includes('DFB_PORTAL_PARTNERS')) {
      return this.$i18n.t('userPartner');
    }
    else {
      return this.$i18n.t('user');
    }
  }

  get totalPayments() {
    return paymentsModule.paymentsTotals?.payments_total
  }

  get totalPendings() {
    return paymentsModule.paymentsTotals?.total_pending
  }

  get totalExpenses() {
    return paymentsModule.paymentsTotals.expenses_total;
  }

  get payments() {
    return paymentsModule.payments
  }

  get paymentssLength() {
    return this.payments.length;
  }

  get searchLabel() {
    return this.$t("paymentsSearch")
  }

  get paymentsLength() {
    return paymentsModule.payments.length
  }

  formatBalance(balance: number) {
    return formatAmount(balance);
  }

  async mounted () {
    /*
     TODO: Handle installations properly when UX is clear.
     */

    // Clear the search input and all of the other filters when visiting the page
    paymentsModule.setSearch('');
    paymentsModule.setFilters('');
    paymentsModule.setQueryObject({});

    const dateFrom = dayjs().startOf('day').toISOString();
    const dateTo = dayjs().endOf('day').toISOString();

    paymentsModule.getPaymentsAction(`?date_from=${dateFrom}&date_to=${dateTo}`);
    commonModule.getPersonnelPartnersCombo();
    commonModule.initSnackbar({});
    commonModule.getPaymentTypeCombo();
    paymentsModule.getPaymentsTotals(`?date_from=${dateFrom}&date_to=${dateTo}`);
    paymentsModule.setFilters(`?date_from=${dateFrom}&date_to=${dateTo}`);

    paymentsModule.queryObject.date_from = dateFrom;
    paymentsModule.queryObject.date_to = dateTo;
    paymentsModule.setFilters(`?date_from=${dateFrom}&date_to=${dateTo}`);
  }

  destroyed() {
    paymentsModule.clearPayments();
  }

  get isLoading() {
    return paymentsModule.isLoadingTable;
  }

  async refreshData() {
    paymentsModule.setIsLoadingTable(true);
    await paymentsModule.getPaymentsAction(paymentsModule.filters);
    await paymentsModule.getPaymentsTotals(paymentsModule.filters);
    paymentsModule.setIsLoadingTable(false);
  }

  async editItem(item: Record<string, any>) {
    if(item.finc_type === 'EXPENSE') {
      commonModule.showModal({ name: 'edit-expense-modal', payload: { expense: item }})
    } else {
      const installation = await installationsModule.getInstallationInfo(item.installation_id);
      const job = await jobsModule.getJobDetails(item.job_id);
      commonModule.showModal({ name: 'edit-payment-modal', payload: { payment: item, installation: installation, job: (job as any).jobDetails[0] }})
    }
  }

  deleteItem(item: Record<string, any>) {
    if(item.finc_type === 'EXPENSE') {
      commonModule.showModal({ name: 'delete-expense-modal', payload: { expense: item }})
    } else {
      commonModule.showModal({ name: 'delete-payment-modal', payload: { payment: item }})
    }
  }

  async downloadExpenseFile(expense: Record<string, any>) {
    let downloadFile = await expensesModule.getExpensionFile(expense.id);
    downloadFile = new File([downloadFile], expense.file_name);
    FileSaver.saveAs(downloadFile);
  }

  timer: number;
  onSearchChange(val: string) {
    clearTimeout(this.timer)

    this.timer = setTimeout(async () => {
      paymentsModule.setIsLoadingTable(true);
      if (val && val.length > 0) {
        paymentsModule.setSearch(val);
        paymentsModule.queryObject.search = paymentsModule.search;
      }
      else {
        paymentsModule.setSearch('');
        delete paymentsModule.queryObject.search;
      }
      this.$router.replace({ query: paymentsModule.queryObject });

      // Check if user selected any of the queries
      const queryIndex = this.$route.fullPath.indexOf('?');
      if (queryIndex >= 0) {
        const query = this.$route.fullPath.substring(queryIndex);
        paymentsModule.setFilters(query);
      } else {
        paymentsModule.setFilters('');
      }

      await paymentsModule.getPaymentsAction(paymentsModule.filters);
      await paymentsModule.getPaymentsTotals(paymentsModule.filters);
      paymentsModule.setIsLoadingTable(false);
    }, 500);
  }

  downloadPdfLengthCheck() {
    return this.paymentsLength > 0 && this.paymentsLength <= pagesExportLimit();
  }

  async donwloadPaymentsPdf() {
    this.isDownloadingPaymentsPdf = true;
    const utcOffset = dayjs().utcOffset();

    const data = { 'utcOffset': utcOffset, 'language': localStorage.getItem('lang') };
    
    const company = await companyModule.getCompanyInfo();
    const filename = `${templatesFilenameLanguage()}_${company.entity_name}_DfB_payments_expenses_${dayjs().format('YYYY_MM_DD_HH_mm_ss')}.pdf`.replace(/ /g, '_');
    const pdf = await  paymentsModule.getPaymentsPdfExportAction({ query: paymentsModule.filters, data: data });
    const file = new File([pdf], filename);
    FileSaver.saveAs(file);

    this.isDownloadingPaymentsPdf = false;
  }

  async openInstallationSideView(item: Record<string, any>) {
    paymentsModule.setIsLoadingTable(true);

    if(item.installation_id) {
      try {
        await installationsModule.getInstallationInfo(item.installation_id);
        commonModule.showSideview({name: 'installations-sideview', payload: { installationId: item.installation_id, tab: 1, jobId: item.job_id }});
      } catch (error) { paymentsModule.setIsLoadingTable(false); }
    } else { // company expense
      expensesModule.unsetCurrentExpense();
      await expensesModule.getExpenseDetails(item.id);
      commonModule.showModal({ name: 'company-expense-modal'});

    }
    paymentsModule.setIsLoadingTable(false);
  }

}
