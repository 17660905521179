
import { Component, Vue } from 'vue-property-decorator'
import { appointmentsModule, commonModule, companyModule, installationsModule, actionTypesModule, filterModule, itemsModule, tasksModule } from "@/store/modules/store-accessor";
import TheLoadingIndicator from '@/components/TheLoadingIndicator.vue'
import CalendarFilter from '../components/Filter/CalendarFilter.vue'
import { roundMinutes, primaryItemText } from "../helpers/data";
import dayjs from 'dayjs';
import { TimeUtil } from '@/helpers/TimeUtil';


@Component({
  components: { CalendarFilter, TheLoadingIndicator }
})

export default class Calendar extends Vue {

  focus = ""
  
  type =  "category"; //default type for the calendar
  label = '';

  typeToLabel = {
    month: this.$t("month_uppercase"),
    week: this.$t("week_uppercase"),
    day: this.$t("day_uppercase"),
    category: this.$t("day_technicians_uppercase"),
    category_all: this.$t("day_all_uppercase"),
  }

  nameToType(name: any) {
      if (name == 'month') {
        this.type = 'month'
        this.label =  this.$t("month_uppercase").toString()
        localStorage.setItem("calendar_type", 'month');
      }
      else if (name == 'week') {
        this.type = 'week'
        this.label =  this.$t("week_uppercase").toString()
        localStorage.setItem("calendar_type", 'week');
      }
      else if (name == 'day') {
        this.type = 'day'
        this.label = this.$t("day_uppercase").toString()
        localStorage.setItem("calendar_type", 'day');
      }
      else if (name == 'category') {
        this.type = 'category'
        this.label = this.$t("day_technicians_uppercase").toString()     
        localStorage.setItem("calendar_type", 'category'); 
        appointmentsModule.setCategories()
      }
      else if (name == 'category_all') {
        this.type = 'category'
        this.label = this.$t("day_all_uppercase").toString()   
        localStorage.setItem("calendar_type", 'category_all');  
        appointmentsModule.setCategories()
      }
      else {
        this.type = 'category'
        this.label = this.$t("day_technicians_uppercase").toString()     
        localStorage.setItem("calendar_type", 'category'); 
        appointmentsModule.setCategories()
      }
  }

  selectedEvent = []
  selectedElement = null
  selectedOpen = false
  events: any
  breadcrumbs = []
  value: '';
  ready = false;

  private get calendarInstance (): Vue & {
        prev: () => void; 
        next: () => void; 
        checkChange: () => void; 
        getFormatter: (format: any) => any; 
        updateTimes: () => any; 
        scrollToTime: (time: any) => any;
        timeToY: (time: number | string | { hour: number, minute: number }, clamp: boolean) => number | false
        timeToX: (time: any) => any;
        times: {
              now: any & {
                  hour: number;
                  minute: number;
                  second: number;
              }
        };
        parsedValue: {
            date: any;
        };
        start: string;
    } {
    return this.$refs.calendar as Vue & {
        prev: () => void; 
        next: () => void; 
        checkChange: () => void; 
        getFormatter: (format: any) => any; 
        updateTimes: () => any; 
        scrollToTime: (time: any) => any;
        timeToY: (time: number | string | { hour: number, minute: number }, clamp: boolean) => number | false
        timeToX: (time: any) => any;
        times: {
            now: any;
            today: any
        };
        parsedValue: {
            date: any;
        };
        start: string;
    }
  }

  get categories() {
    return appointmentsModule.categories;
  }

  get appointments() {
    return appointmentsModule.calendarAppointments.appointments;
  }

  get cal () {
    return this.ready ? this.calendarInstance : null
  }

  get nowY () {
    return this.cal ? this.cal.timeToY(this.cal.times.now, false) + 'px' : '-10px'
  }

  get isLoading() {
    return appointmentsModule.isLoadingIndicator;
  }

  eventDescription(event: any, type: any) {
    let event_arr;
    if (event.isTask && (!event.installation_id || (event.installation_id && !event.task_category))) {
      event_arr = [`${event.name}`];
    } else if(event.task_category && event.task_category[0] === 1 ) {
      event_arr = [`${event.name}`, `${event.address}`]
    } else {
      event_arr = event.is_business 
      ?
      [`${event.entity_name} (${event.client_name})`, `${event.address ? event.address : event.area}`, event.name, event.assignee_id]
      :
      [event.client_name, `${event.address ? event.address : event.area}`, event.name, event.assignee_id]
    }
    
    if (type == 'category' && !event.isTask) {
      event_arr.pop();
    }

    return event_arr.filter(Boolean).map(
      (element, index) =>
        {
          if(index % 2 == 0)
            return element
          else 
            return `<b>${element}</b>`
        }
    ).join(' | ')
  }

  async mounted() {
    this.ready = true;
    appointmentsModule.setQueryObject({});
    this.nameToType(localStorage.getItem("calendar_type")) 
    await commonModule.getPersonnelCombo();
    await commonModule.getPersonnelPartnersCombo();
    await appointmentsModule.getCategories();
    if (localStorage.getItem("calendar_type") === 'month' || localStorage.getItem("calendar_type") === 'week') {
      const dateFrom = appointmentsModule.queryObject.date_from.toString();
      const dateTo = appointmentsModule.queryObject.date_to.toString();
      await appointmentsModule.getCalendarAppointmentsAction(`?date_from=${dateFrom}&date_to=${dateTo}`);
    } else {
      //Adding +/-1 day to range in order to show events in the calendar that are not rendered propery by vue-calendar
      await appointmentsModule.getCalendarAppointmentsAction(`?date_from=${dayjs().subtract(1, 'day').startOf('day').toISOString()}&date_to=${dayjs().add(1, 'day').endOf('day').toISOString()}`);
      appointmentsModule.queryObject.date_from = `${dayjs().startOf('day').toISOString()}`;
      appointmentsModule.queryObject.date_to = `${dayjs().endOf('day').toISOString()}`;
    }
    await filterModule.getCalendarFilters();
    await commonModule.getPaymentTypeCombo();
    commonModule.initSnackbar({});
    actionTypesModule.getActionTypesCombo();
    commonModule.getDurationCombo();
    await itemsModule.getCompanyPrimaryInventoryItems();
    appointmentsModule.setIsLoadingIndicator(false);
  }

  destroyed() {
    appointmentsModule.clearCalendarAppointments();
  }

  addAppointmentOnClick (tms: any) {
    let assignee_id;
    if(tms.category) {
      const assigneeIndex = this.categories.name.indexOf(tms.category);
      const assigneeId = this.categories.id[assigneeIndex];
      assignee_id = {
        name: tms.category,
        id: assigneeId
      }
  }
    let selectedTime = `${tms.hour}:${roundMinutes(tms.minute)}`
    if (this.selectedOpen == false) {
      if(assignee_id) {
        commonModule.showModal({ name: 'add-appointment-modal', payload: { scheduled_start_time: selectedTime, scheduled_start: tms.date, assignee_id: assignee_id} })
      } else {
        if(tms.hasTime === false) {
          commonModule.showModal({ name: 'add-appointment-modal', payload: { scheduled_start: tms.date} })
        } else {
          commonModule.showModal({ name: 'add-appointment-modal', payload: { scheduled_start_time: selectedTime, scheduled_start: tms.date} })
        }
      }
    }
  }
  
  closeSelectedCard() {
    this.selectedOpen = false;
  }

  formatDate (date: Date): any {
    if (date == undefined || date == null) {
      return '-'
    } else {
      return TimeUtil.formatDateTime(date)
    }
  }

  async selectInstallation(event: Record<string, any>) {
    if(event.installation_id) {
      const installationExists = await installationsModule.getInstallationInfo(event.installation_id);
      // Check if installation still exists

      if (installationExists) {
        if(event.isTask) {
          commonModule.showSideview({ name: 'installations-sideview', payload: { installationId: event.installation_id, tab: 3, taskId: event.id } });
        } else {
          commonModule.showSideview({ name: 'installations-sideview', payload: { installationId: event.installation_id, tab: 2, appointmentId: event.id } });
        }
      }
      // else installation has been deleted (?)
    } else if(!event.installation_id) { // orphan task
      tasksModule.unsetCurrentTask();
      await tasksModule.getTaskDetails(event.id);
      commonModule.showModal({ name: 'open-orphan-task-modal' });
    }
  }

  
  async editEvent(event: Record<string, any>) {
    this.selectedOpen = false;

    if(event.isTask) {
      event.title = event.name;
      event.type_name = event.name;
      event.type_color = event.color;
      event.assignee = this.getAssigneeInfo(event.assignee_id);
      commonModule.showModal({ name: 'edit-task-modal', payload: { task: event }})
    } else {
      await itemsModule.getInstallationPrimaryInventoryItems(event.installation_id)
      event.type_name = event.name;
      event.type_color = event.color;
      event.scheduled_start = this.formatDate(event.realStartDateTime);
      event.scheduled_end = this.formatDate(event.realEndDateTime);
      
      commonModule.showModal({ name: 'edit-appointment-modal', payload: { appointment: event }})
    }
  }

  viewDay({ date }: { date: any }) {
    this.focus = date;
    this.type = "day";
  }

  getEventColor(event: any) {
    return event.color;
  }

  setToday() {
    this.focus = "";
  }

  prev(): void {
    this.calendarInstance.prev();
  }

  next(): void {
    this.calendarInstance.next();
  }

  getCurrentTime () {
    return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
  }

  getEventTextColor(event: any) {
    return event.textColor;
  }

  scrollToTime () {
    const time = this.getCurrentTime()
    const first = Math.max(0, time - (time % 30) - 30)

    if (this.cal)
      this.cal.scrollToTime(first)
  }

  updateTime () {
    setInterval(() => this.cal ? this.cal.updateTimes() : false, 60 * 1000)
  }

  showEvent({ nativeEvent, event }: { nativeEvent: any, event: any }) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() =>
        requestAnimationFrame(() => (this.selectedOpen = true))
      );
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  async fetchAppointments({ start, end }: { start: any, end: any }) {
    appointmentsModule.queryObject.date_from = `${dayjs(start.date).subtract(1, 'day').startOf('day').toISOString()}`;
    appointmentsModule.queryObject.date_to = `${dayjs(end.date).add(1, 'day').endOf('day').toISOString()}`;
    appointmentsModule.setCalendarSelectedType(this.type);

    this.$router.replace({ query: appointmentsModule.queryObject }).catch(err => {/* */ });


    const queryIndex = this.$route.fullPath.indexOf('?');
    if(queryIndex >= 0) {
      const query = this.$route.fullPath.substring(queryIndex);
      appointmentsModule.setFilters(query);
    } else {
      appointmentsModule.setFilters('');
      appointmentsModule.setQueryObject(appointmentsModule.queryObject); // Will be empty ({})
    }
    appointmentsModule.setCalendarEndDate(`${dayjs(end.date).toISOString()}`);
    appointmentsModule.setIsLoadingIndicator(true);
    await appointmentsModule.getCalendarAppointmentsAction(appointmentsModule.filters);
    appointmentsModule.setIsLoadingIndicator(false);
    this.updateRange({ start, end });
  }

  async updateRange({ start, end }: { start: any, end: any }) {
    this.events = appointmentsModule.calendarAppointments;
  }

  rnd(a: any, b: any) {
    return Math.floor((b - a + 1) * Math.random()) + a;
  }

  getItemText(id: number) {
    return primaryItemText(id, itemsModule.companyPrimaryItems);
  }

  async refreshData() {
    appointmentsModule.setIsLoadingIndicator(true);
    commonModule.getDurationCombo();
    actionTypesModule.getActionTypesCombo();
    await appointmentsModule.getCalendarAppointmentsAction(appointmentsModule.filters);
    await filterModule.getCalendarFilters();
    await appointmentsModule.getCategories();
    appointmentsModule.setIsLoadingIndicator(false);
  }

  exportAppointments() {

    const dateObj = {
      date_from: dayjs(this.calendarInstance.parsedValue.date).startOf('day').toISOString(),
      date_to: dayjs(this.calendarInstance.parsedValue.date).endOf('day').toISOString()
    }

    commonModule.showModal({ name: 'export-calendar-modal', payload: { day: dateObj } });
  }
  techniciansOrPersonnelExist() {
    return commonModule.techniciansCombo.length > 0 || commonModule.personnelCombo.length > 0 ? true : false;
  }

  get jobDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }

  showStrikeThrough(event: Record<string, any>) {
    return event.status_id === 1;
  }

  getAssigneeInfo(name: string) {
    const combo: any = commonModule.personnelPartnersCombo;
    const foundItem = combo.find((item: any) => item.name === name);
    if(foundItem) {
      return { name: foundItem.name, id: foundItem.id, is_partner: foundItem.is_partner };
    } else {
      return '';
    }
  }


  editTask (task: Record<string, any>){
    commonModule.showModal({ name: 'edit-task-modal', payload: { task: task }})
  }

  completeTask (task: Record<string, any>) {
    this.closeSelectedCard();
    commonModule.showModal({ name: 'complete-task-modal', payload: { task: task}})
  }

  async completeTaskWithAppointment (task: Record<string, any>) {
    this.closeSelectedCard();
    const installation = await installationsModule.getInstallationInfo(task.installation_id);
    commonModule.showModal({ name: 'add-appointment-modal', payload: { task_id: task.id, installation: installation, action_type_id: task.action_type_id, assignee_id: task.assignee_id, notes: task.notes, installation_item_id: task.installation_item_id}})
  }

  async completeTaskWithJob (task: Record<string, any>) {
    this.closeSelectedCard();
    const installation = await installationsModule.getInstallationInfo(task.installation_id);
    commonModule.showModal({ name: 'add-job-modal', payload: { task_id: task.id, installation: installation, action_type_id: task.action_type_id, assignee: task.assignee_id, notes: task.notes, installation_item_id: task.installation_item_id}})
  }

  async endAppointment (appointment: Record<string, any>) {
    this.closeSelectedCard();
    const installation = await installationsModule.getInstallationInfo(appointment.installation_id);
    commonModule.showModal({ name: 'end-appointment-modal', payload: { appointment: appointment, installation: installation }})
  }

};
